import React, { useState } from 'react';
import { useLoadScript, Autocomplete } from '@react-google-maps/api';
import SuccessModal from '../components/SuccessModal';
import Navigation from '../components/Navigation'; // Import the Navigation component
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faPhone, faAt } from '@fortawesome/free-solid-svg-icons';
import '../styles/Home.css';
import logo from '../assets/logo.svg';
import axios from 'axios';
const Home = () => {
  const [formData, setFormData] = useState({
    address: '',
    phone: '',
    email: ''
  });
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ['places']
  });



  const handleSubmit = async (e) => {
    console.log("handle submit clcikeddd-----------");
    e.preventDefault();
    setErrorMessage('');

    try {
  
      const response = await axios.post(process.env.API + '/send-email', formData);


      if (response.status === 200) {
        setShowModal(true);
        setFormData({ address: '', phone: '', email: '' });
      } else {
        setErrorMessage('Failed to send data. Please try again later.');
      }
    } catch (error) {
      console.error('Error sending data:', error);
      setErrorMessage('An error occurred. Please try again.');
    }
  };

  return (
    <div className="home">
      <Navigation /> {/* Add the Navigation component here */}

      <div className="header">
        <div className="logo">
          <img src={logo} alt="Noosa Airbnb Approvals" />
        </div>
      </div>

      
      <form onSubmit={handleSubmit}>
        {isLoaded && (
          <div className="input-container">
            <FontAwesomeIcon icon={faSearch} className="input-icon" />
            <Autocomplete
              onLoad={autocomplete => console.log('Autocomplete loaded')}
              onPlaceSelected={place => {
                setFormData(prev => ({
                  ...prev,
                  address: place.formatted_address
                }));
              }}
            >
              <input
                type="text"
                placeholder="Address"
                value={formData.address}
                onChange={(e) => setFormData(prev => ({
                  ...prev,
                  address: e.target.value
                }))}
              />
            </Autocomplete>
          </div>
        )}
        
        <div className="input-container">
          <FontAwesomeIcon icon={faPhone} className="input-icon" />
          <input
            type="tel"
            placeholder="Phone"
            value={formData.phone}
            onChange={(e) => setFormData(prev => ({
              ...prev,
              phone: e.target.value
            }))}
          />
        </div>
        
        <div className="input-container">
          <FontAwesomeIcon icon={faAt} className="input-icon" />
          <input
            type="email"
            placeholder="Email"
            value={formData.email}
            onChange={(e) => setFormData(prev => ({
              ...prev,
              email: e.target.value
            }))}
          />
        </div>
        
        <button type="submit">Get Your Approval Here</button>
      </form>

      <div className="info">
        <p>The fast track to Attaining approval to short term holiday let your Noosa Property.</p>
        <p>We're Noosa's only STA approval specialists</p>
        <p>Guaranteed to cost less than any local law consultant or firm.</p>
        <p>Applications completed & submitted within 24hrs of initial consultation.</p>
        
        <div className="pricing">
          <p>Fixed price approvals</p>
          <p>Unit $1295.95</p>
          <p>House $1695.95</p>
        </div>
        <div className='licence'>
            inc council appliocation fee & first years licence fee
        </div>
      </div>

      {showModal && <SuccessModal onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default Home;
